import React from "react";
import ReactDOM from "react-dom";
// import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createGlobalStyle } from "styled-components";
// import { Provider } from "react-redux";
import { fixaColorScheme } from "@coworker/common/config/colorScheme";
// import store, { firebaseProviderProps } from "@coworker/common/config/store";
import "@coworker/locales";
// import { BrowserRouter as Router } from "react-router-dom";
// import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import versionJson from "@coworker/functions/src/version.json";
import * as Sentry from "@sentry/react";
import { shouldReportToSentry } from "@coworker/common/helpers/sentry.helpers";
import { gaService } from "@coworker/common/services/google-analytics.service";
import styled from "styled-components";

const Container = styled.div`
  height: 50%;
  width: 40%;
  margin: auto;
`;

const OuterContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 150px;
`;

const RedirectButton = styled.input`
  padding: 15px;
  cursor: pointer;
  background-color: black;
  color: white;
  margin-top: 15px;
  align-text: center;
`;

const StyledLink = styled.a`
  cursor: pointer;
  padding-top: 15px;
`;
const Global = createGlobalStyle`
  ${() => fixaColorScheme}

  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-BoldItalic.woff2) format('woff2'), url(/fonts/NotoSansIKEA-BoldItalic.woff) format('woff');
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-Italic.woff2) format('woff2'), url(/fonts/NotoSansIKEA-Italic.woff) format('woff');
    font-style: italic;
  }
  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-Bold.woff2) format('woff2'), url(/fonts/NotoSansIKEA-Bold.woff) format('woff');
    font-weight: bold;
  }
  @font-face {
    font-family: 'NotoSansIKEA';
    src: url(/fonts/NotoSansIKEA-Regular.woff2) format('woff2'), url(/fonts/NotoSansIKEA-Regular.woff) format('woff');
  }
  body, html, #root {
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: 0;
  }
  * {
    -webkit-tap-highlight-color: transparent;
  }
  a  {
  text-decoration: none;
    user-select: none;
    overscroll-behavior-y: contain;
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: var(--white);
  }
  ::-webkit-scrollbar-track {
    width: 5px;
    height: 5px;
    background: var(--white);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0;
  }
`;

// function AppContainer() {
//   return (
//     <>
//       <Global />
//       <Provider store={store}>
//         <ReactReduxFirebaseProvider {...firebaseProviderProps}>
//           <Router>
//             <Sentry.ErrorBoundary>
//               <App />
//             </Sentry.ErrorBoundary>
//           </Router>
//         </ReactReduxFirebaseProvider>
//       </Provider>
//     </>
//   );
// }

function Message() {
  const redirectUrl =
    process.env.REACT_APP_ENV === "prod"
      ? "https://fixa-admin.ingka.com"
      : "https://fixa-admin-stage.ingka.com";
  const text =
    process.env.REACT_APP_ENV === "prod"
      ? "fixa-admin.ingka.com"
      : "fixa-admin-stage.ingka.com";
  return (
    <>
      <Global />
      <OuterContainer>
        <Container>
          <h2>Fixa admin has a new link</h2>
          <div>
            Please use new Fixa admin url:<b>{text}</b> and replace it if you
            have saved it anywhere.
          </div>
          <StyledLink href={redirectUrl}>
            <RedirectButton type="button" value={`Go to ${text}`} />
          </StyledLink>
        </Container>
      </OuterContainer>
    </>
  );
}

if (
  process.env.REACT_APP_ENV === "prod" &&
  process.env.REACT_APP_PROJECT_ID !== "ikea-coworker-app-prod" // EU prod environment is handled via Firebase Analytics integration
) {
  gaService.init("G-TBK5FH8YF4");
}

const env = process.env.REACT_APP_ENV;
Sentry.init({
  dsn: "https://38b4f8bda4b4429dbc568af22f9f769d@o478484.ingest.sentry.io/5515344",
  environment: env,
  release: `fixa-admin-app@${versionJson.version}`,
  autoSessionTracking: true,
  enabled: shouldReportToSentry(env),
  maxValueLength: 13337,
  attachStacktrace: true,
  ignoreErrors: [
    "Network Error",
    "Error: Network Error",
    "NETWORK_ERROR - Error when requesting resource",
    "You do not have your profile created.",
  ],
});

const root = document.getElementById("root");
try {
  ReactDOM.render(<Message />, root);
} catch (error) {
  let message = "Failed to start Admin app.";
  if (window.navigator.userAgent.includes("Edge/"))
    message +=
      " The FIXA Admin app supports Google Chrome." +
      " It looks like you're using Microsoft Edge. We recommend getting the latest Edge Chromium." +
      " If you're using an ICC client, the upgrade has module name Microsoft_EDGE_Chromium_WIN10_1.1.EN and this module can be ordered through support.";
  root.innerText = message;
  console.error(message, error);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (
  process.env.NODE_ENV !== "production" ||
  process.env.REACT_APP_ENV === "ci"
) {
  serviceWorker.unregister();
} else {
  serviceWorker.register();
}
