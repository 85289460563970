import { Trans } from "react-i18next";

let ExportedTrans = Trans;

if (window.BLABLA_LANGUAGE || localStorage.getItem("BLABLA_LANGUAGE")) {
  ExportedTrans = function blabla() {
    return "blabla";
  };
}

export default ExportedTrans;
